/*
 * bodyclasses.js
 * Convenience CSS body classes
 * body.authenticated = when customer is logged in
 * body.page-specific = for targeting specific pages
 */

import { waitForGlobal } from "./utilities";

async function pageLocation() {
  const { pathname, hash } = window.location;
  const match = pathname.match(/\/(nl|fr)\/(.+)/);
  if (match) {
    let className = "page-" + match[2].replace(/\/$/, "").replace(/\/|\?/g, "-");
    if (hash) {
      className += hash.replace("#", "-");
    }
    className = className.toLowerCase();
    className = className.replace(/cogo-/g, "collectandgo-"); // prevent global CSS from selecting body and overriding font-family with icons font
    document.body.className = document.body.className.replace(/\bpage-\S+/g, "").trim();
    document.body.classList.add(className);
  }
}

async function authenticated() {
  const XTRA = await waitForGlobal("XTRA");
  if (XTRA?.user?.state?.() === "authenticated") {
    document.body.classList.add("authenticated");
  }
}

async function lockerRegion() {
  const XTRA = await waitForGlobal("XTRA");
  const gentRegionPostcodes = [9000, 9030, 9031, 9032, 9040, 9041, 9042, 9050, 9051, 9052, 9070, 9090, 9230, 9820]; // remove 1070
  const userZip = Number(XTRA?.user?.zip?.());
  if (userZip && gentRegionPostcodes.includes(userZip)) {
    document.body.classList.add("locker-region");
  }
}

// async function homeDeliveryNewlyAvailable() {
//   const WCParamJS = await waitForGlobal("WCParamJS");
//   const collectionPoints = [7727, 7751, 7721, 7749, 7705]; // remove 7711
//   const id = Number(WCParamJS?.colShop?.collectionPointId);
//   if (id && collectionPoints.includes(id)) {
//     document.body.classList.add("home-delivery-newly-available");
//   }
// }

document.addEventListener("DOMContentLoaded", async function () {
  pageLocation();
  await authenticated();
  await lockerRegion();
  // await homeDeliveryNewlyAvailable();
  window.addEventListener("popstate", pageLocation);
  window.addEventListener("hashchange", pageLocation);

  const vueAppElement = document.querySelector("page-content[data-v-app]");
  if (vueAppElement && vueAppElement.__vue_app__) {
    const router = vueAppElement.__vue_app__.config.globalProperties.$router;
    if (router?.afterEach) {
      router.afterEach(pageLocation);
    }
  }
});
