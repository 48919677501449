/*
 * utilities.js
 * webbie-scripts utilities
 */

const wait = (ms) => new Promise((res) => setTimeout(res, ms));

export const waitForGlobal = async (globalObjectName, maxRetries = 50, interval = 100) => {
  let retries = 0;
  while (!window[globalObjectName] && retries < maxRetries) {
    await wait(interval);
    retries++;
    interval += 50;
  }
  if (!window[globalObjectName]) {
    throw new Error(`Global object ${globalObjectName} is not available after ${maxRetries} retries.`);
  }
  return window[globalObjectName];
};
