/*
 * zoekrangschikking-info.js
 * Display a message on search page about the order of results
 */

const SearchTermWatcher = {
  searchTitleTooltip() {
    if (!window.location.href.includes("searchTerm")) return;
    const searchTitleContainer = document.querySelector("#mainContent .title-container");
    if (!searchTitleContainer || document.querySelector(".zoekrangschikking")) return;

    const isNL = (window).WCParamJS.langId === "-1001";
    const tooltipNL = 'Meer weten over de rangschikking van de resultaten? <a href="/colruyt/nl/zoekresultaten-rangschikking" data-tms-banner-name="omnibusSearchLeesMeer" data-tms-banner-id="omnibus">Lees meer</a>';
    const tooltipFR = 'Vous souhaitez en savoir plus sur le classement des résultats de recherche ? <a href="/colruyt/fr/classement-resultats-recherches" data-tms-banner-name="omnibusSearchLeesMeer" data-tms-banner-id="omnibus">En savoir plus</a>';

    const tooltipTxt = document.createElement("p");
    tooltipTxt.innerHTML = isNL ? tooltipNL : tooltipFR;

    const tooltipDiv = document.createElement("div");
    tooltipDiv.classList.add("zoekrangschikking");
    tooltipDiv.appendChild(tooltipTxt);
    searchTitleContainer.insertAdjacentElement("afterend", tooltipDiv);
  },
  init() {
    this.searchTitleTooltip();
    new MutationObserver(() => this.searchTitleTooltip()).observe(document.body, {
      childList: true,
      subtree: true,
    });
  },
};
SearchTermWatcher.init();
