/*
 * fix-page-content-stutter.js
 * Fixes the annoying content stutter when header gets fixed position
 */

document.addEventListener("DOMContentLoaded", () => {
  const header = document.querySelector(".c-header__secondary");
  const getMainContent = () => document.querySelector(".page-content");

  const updatePadding = () => {
    const mainContent = getMainContent();
    if (mainContent && header) {
      mainContent.style.paddingTop = header.classList.contains("--fixed") ? `${header.offsetHeight}px` : "0";
    }
  };

  new MutationObserver(updatePadding).observe(header, {
    attributes: true,
    attributeFilter: ["class"],
  });

  window.addEventListener("resize", updatePadding);

  const contentObserver = new MutationObserver(updatePadding);
  contentObserver.observe(document.body, {
    childList: true,
    subtree: true,
  });
});
