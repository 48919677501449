/*
 * extra-nav-links.js
 * Add some extra link to mobile hamburger nav and destkop top navigation like "Levering aan huis" and "Afhaalpunt in jouw buurt"
 */

document.addEventListener("DOMContentLoaded", function () {
  const lang = window.WCParamJS.langId === "-1001" ? "nl" : "fr";

  const desktopNL = `
  <div class="extraNavLinks-desktop">
    <ul>
      <li class="new-lockers-in-region">
        <a data-tms-intpromo-name="header_link-lockers" target="_blank" href="https://vandaag.collectandgo.be/nl/meer-info/?utm_source=collectandgo_desktop&utm_medium=website&utm_campaign=extranavlinks_with_badge">
          <svg viewBox="0 0 34 12" style="width: 36px; height: auto">
            <rect width="100%" height="100%" rx="6" fill="#82e0ae"></rect>
            <text x="50%" y="50%" text-anchor="middle" dominant-baseline="central" fill="#1c3661" class="tk-mic-32-new-rounded-web" font-weight="bold" font-style="italic" font-size="10">nieuw</text>
          </svg>
          <span>Lockers in Gent</span>
        </a>
      </li>
      <li>
        <a data-tms-intpromo-name="header_link-AHL" href="/colruyt/nl/levering-aan-huis">
          <svg class="home-delivery-newly-available-badge" viewBox="0 0 94 12" style="width: 94px; height: auto">
            <rect width="100%" height="100%" rx="6" fill="#82e0ae"></rect>
            <text x="50%" y="50%" text-anchor="middle" dominant-baseline="central" fill="#1c3661" class="tk-mic-32-new-rounded-web" font-weight="bold" font-style="italic" font-size="10">nieuw in jouw regio</text>
          </svg>
          <span>Levering aan huis</span>
        </a>
      </li>
      <li>
        <a data-tms-intpromo-name="header_link-afhaalpunt" href="/colruyt/nl/faq-vind-afhaalpunt">
          <span>Afhaalpunt in jouw buurt</span>
        </a>
      </li>
    </ul>
  </div>
  `;
  const desktopFR = `
  <div class="extraNavLinks-desktop">
    <ul>
      <li>
        <a data-tms-intpromo-name="header_link-AHL" href="/colruyt/fr/faq-trouver-point-enlevement">
          <span>Trouvez un point d'enl&egrave;vement</span>
        </a>
      </li>
      <li>
        <a data-tms-intpromo-name="header_link-afhaalpunt" href="/colruyt/fr/livraison-a-domicile">
          <svg class="home-delivery-newly-available-badge" viewBox="0 0 125 12" style="width: 125px; height: auto">
              <rect width="100%" height="100%" rx="6" fill="#82e0ae"></rect>
              <text x="50%" y="50%" text-anchor="middle" dominant-baseline="central" fill="#1c3661" class="tk-mic-32-new-rounded-web" font-weight="bold" font-style="italic" font-size="10">nouveau dans votre r&eacute;gion</text>
          </svg>
          <span>Livraison &agrave; domicile</span>
        </a>
      </li>
    </ul>
  </div>
  `;

  const headerRight = document.querySelector(".c-header__top .c-header__right");
  if (headerRight) {
    headerRight.insertAdjacentHTML("afterbegin", lang === "nl" ? desktopNL : desktopFR);
  }

  const mobileNL = `
  <div class="extraNavLinks-mobile">
    <ul>
      <li class="new-lockers-in-region">
        <a data-tms-intpromo-name="header_link-lockers_mobile" target="_blank" href="https://vandaag.collectandgo.be/nl/meer-info/?utm_source=collectandgo_mobile&utm_medium=website&utm_campaign=extranavlinks_with_badge">
          <span>Lockers in Gent</span>
          <svg viewBox="0 0 40 20" style="width: 40px; height: auto">
            <rect width="100%" height="100%" rx="10" fill="#82e0ae"></rect>
            <text x="50%" y="50%" text-anchor="middle" dominant-baseline="central" fill="#1c3661" class="tk-mic-32-new-rounded-web" font-weight="bold" font-style="italic" font-size="10">nieuw</text>
          </svg>
        </a>
      </li>
      <li>
        <a data-tms-intpromo-name="header_link-AHL_mobile" href="/colruyt/nl/levering-aan-huis">
          <span>Levering aan huis</span>
          <svg class="home-delivery-newly-available-badge" viewBox="0 0 100 20" style="width: 100px; height: auto">
            <rect width="100%" height="100%" rx="10" fill="#82e0ae"></rect>
            <text x="50%" y="50%" text-anchor="middle" dominant-baseline="central" fill="#1c3661" class="tk-mic-32-new-rounded-web" font-weight="bold" font-style="italic" font-size="10">nieuw in jouw regio</text>
          </svg>
        </a>
      </li>
      <li>
        <a data-tms-intpromo-name="header_link-afhaalpunt_mobile" href="/colruyt/nl/faq-vind-afhaalpunt">
          <span>Afhaalpunt in jouw buurt</span>
        </a>
      </li>
    </ul>
  </div>
  `;
  const mobileFR = `
  <div class="extraNavLinks-mobile">
    <ul>
      <li>
        <a data-tms-intpromo-name="header_link-AHL_mobile" href="/colruyt/fr/faq-trouver-point-enlevement#/overview">
          <span>Trouvez un point d'enl&egrave;vement</span>
        </a>
      </li>
      <li>
        <a data-tms-intpromo-name="header_link-afhaalpunt_mobile" href="/colruyt/fr/livraison-a-domicile">
          <span>Livraison &agrave; domicile</span>
          <svg class="home-delivery-newly-available-badge" viewBox="0 0 120 20" style="width: 120px; height: auto">
            <rect width="100%" height="100%" rx="10" fill="#82e0ae"></rect>
            <text x="50%" y="50%" text-anchor="middle" dominant-baseline="central" fill="#1c3661" class="tk-mic-32-new-rounded-web" font-weight="bold" font-style="italic" font-size="10">nouveau dans votre r&eacute;gion</text>
          </svg>
        </a>
      </li>
    </ul>
  </div>
  `;

  const navMobile = document.querySelector("#contentRecommendationWidget_NAVBAR_CLP_MOBILE");
  if (navMobile) {
    navMobile.insertAdjacentHTML("afterend", lang === "nl" ? mobileNL : mobileFR);
  }
});
