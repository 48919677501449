/*
 * prijsaanduiding.js
 * Display a essage on homepage to make users not logged-in aware of which product prices are currently displayed.
 */

function addMessageAndStyles(element) {
  if (element.querySelector(".prijsaanduiding-message")) {
    return; // Message already added to this element
  }

  const isFrench = window.WCParamJS?.langId === "-2";

  const message = isFrench ? "Psst … Vous voyez actuellement l'assortiment et les prix de notre point d'enlèvement à Aalter. <a href=\"identifier\">Connectez-vous</a> pour voir les prix du point d'enlèvement que vous avez choisi." : 'Psst … Je ziet nu het assortiment en de bijhorende prijzen voor ons afhaalpunt in Aalter. <a href="aanmelden">Log in</a> om de prijzen van je gekozen afhaalpunt te zien.';

  const htmlToAdd = `
    <div class="prijsaanduiding-message">
      <div class="messageBoxXT info">
        <div>
          <img src="/cogoecomwcs/static/efood/img/notice-bulb.svg" />
        </div>
        <p><small>${message}</small></p>
      </div>
    </div>
  `;

  element.insertAdjacentHTML("afterbegin", htmlToAdd);
}

function addStyles() {
  if (!document.querySelector("#prijsaanduiding-message-styles")) {
    const style = document.createElement("style");
    style.id = "prijsaanduiding-message-styles";
    style.textContent = `
      .authenticated .prijsaanduiding-message {
        display: none;
      }
      .prijsaanduiding-message {
        margin-top: 30px;
      }
      @media(min-width: 769px) {
        .prijsaanduiding-message {
          margin-top: 20px;
        }
        [id^=contentRecommendationWidget_HOMEPAGE_TIPS] {
          display: flex;
          flex-direction: column-reverse;
        }
      }
    `;
    document.head.appendChild(style);
  }
}

function observeDOM() {
  const targetNode = document.body;
  const config = { childList: true, subtree: true };

  const callback = function (mutationsList, observer) {
    for (const mutation of mutationsList) {
      if (mutation.type === "childList") {
        mutation.addedNodes.forEach((node) => {
          if (node instanceof Element) {
            const elements = node.querySelectorAll("[id^=contentRecommendationWidget_HOMEPAGE_TIPS]");
            elements.forEach((element) => {
              addMessageAndStyles(element);
            });
          }
        });
      }
    }
  };

  const observer = new MutationObserver(callback);
  observer.observe(targetNode, config);

  // Also check for existing elements on initial load
  const existingElements = document.querySelectorAll("[id^=contentRecommendationWidget_HOMEPAGE_TIPS]");
  existingElements.forEach((element) => {
    addMessageAndStyles(element);
  });
}

// Add styles immediately
addStyles();

// Start observing the DOM for changes
observeDOM();
