/*
 * carousel-data-productlist-name.js
 * A quick hack for Joost/Lisanza to add data-productlist-name attribute to all carousel sliders
 */

const handleDocumentChanges = () => {
  const carouselElements = document.querySelectorAll("[id^=catalogEntryRecommendationWidget] > .--carousel");
  carouselElements.forEach((carouselElement) => {
    const siblingHeading = carouselElement.previousElementSibling;
    if (siblingHeading && siblingHeading.classList.contains("o-heading-2")) {
      const titleElement = siblingHeading.querySelector(".titleItems span") || siblingHeading.querySelector(".titleItems h2");
      if (titleElement instanceof HTMLElement) {
        const trimmedText = titleElement.textContent?.trim() || "";
        carouselElement.parentElement.setAttribute("data-productlist-name", trimmedText);
      }
    }
  });
};

const observer = new MutationObserver(handleDocumentChanges);
observer.observe(document.documentElement, {
  childList: true,
  subtree: true,
});
handleDocumentChanges();
