/*
 * banaan-per-tros.js
 * Change "stuk" (piece) or stuks (pieces) to tros or trossen (+FR)
 * https://trello.com/c/pfUwtQrb/92-banaan-per-stuk-verander-naar-per-tros
 */

export function bananaSpecific(unit) {
  return unit
    .replace(/stuks/gi, "trossen")
    .replace(/stuk\(s\)/gi, "tros(sen)")
    .replace(/stuk/gi, "tros")
    .replace(/pièces/gi, "régimes")
    .replace(/pièce\(s\)/gi, "régime(s)")
    .replace(/pièce/gi, "régime");
}

function manipulateBananaElements(elements) {
  Array.from(elements).forEach((element) => {
    const typeTarget = element.querySelector(".add-to-basket__type");
    if (typeTarget instanceof HTMLElement) {
      typeTarget.textContent = bananaSpecific(typeTarget.textContent || "");
    }
    const textTarget = element.querySelector(".add-to-basket__text");
    if (textTarget instanceof HTMLElement) {
      textTarget.textContent = bananaSpecific(textTarget.textContent || "");
    }
    const overlayTargets = element.querySelectorAll(".plp-item-top__overlay__top-text, .overlay__text--top");
    if (overlayTargets) {
      Array.from(overlayTargets).forEach((overlayTarget) => {
        if (overlayTarget instanceof HTMLElement) {
          overlayTarget.textContent = bananaSpecific(overlayTarget.textContent || "");

          const overlayObserver = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
              if (mutation.type === "childList") {
                const oldTextContent = overlayTarget.textContent || "";
                const newTextContent = bananaSpecific(oldTextContent);
                if (oldTextContent !== newTextContent) {
                  overlayTarget.textContent = newTextContent;
                }
              }
            }
          });
          overlayObserver.observe(overlayTarget, { attributes: false, childList: true, subtree: true });
        }
      });
    }
  });
}

const bananas = ["29581", "29013", "29018", "29015", "6865", "3074457345616681589", "3074457345616679547", "3074457345616716179", "3074457345616679549"];
const pid_selector = "[data-product-id='" + bananas.join("'], [data-product-id='") + "']";
const container_selector = ".product_container_" + bananas.join(", .product_container_");

const bananaObserver = new MutationObserver((mutationsList) => {
  for (const mutation of mutationsList) {
    if (mutation.type === "childList" && mutation.target instanceof HTMLElement) {
      const bananaNodes = mutation.target.querySelectorAll(pid_selector + ", " + container_selector);
      if (bananaNodes.length > 0) {
        manipulateBananaElements(bananaNodes);
      }
    }
  }
});

const targetNode = document.querySelector("body > page-content") || document.querySelector("#mainContent");
if (targetNode) {
  bananaObserver.observe(targetNode, { attributes: false, childList: true, subtree: true });
}

document.addEventListener("DOMContentLoaded", () => {
  const bananaNodes = document.querySelectorAll(pid_selector + ", " + container_selector);
  if (bananaNodes.length > 0) {
    manipulateBananaElements(bananaNodes);
  }
});
